import React from 'react';
import Flex, { FlexItem } from 'styled-flex-component';

import SubLayout from '../components/SubLayout';
import Form from '../components/Form';
import { P } from '../components/common/P.style';

const Contact = () => (
  <SubLayout topTitle="お問い合わせ">
    <P css={`
            font-size: 18px;
            margin-top: 2em;
            text-align: center;
        `}>
      お問い合わせの内容によっては、お時間を頂戴する場合がございます。  <br />
      また、お返事を差し上げられない場合もございます。  <br />
    </P>
    <Flex center>
      <FlexItem
        css={`
          margin-top: 80px;
          margin-bottom: 80px;
          width: 96%;
        `}>
        <Form />
      </FlexItem>
    </Flex>
  </SubLayout>
);

export default Contact;
