import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import Flex from 'styled-flex-component';
import AWS from 'aws-sdk';

const Label = styled.label`
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.14px;
  color: #6f6f6f;
  margin-bottom: 10px;
`;

const Required = styled.span`
  color: red;
`;

const Notice = styled.div`
  color: #ff5722;
  margin: 20px 0;
`;

const InputCommonStyle = `
  height: 40px;
  width: 100%;
  line-height: 30px;
  font-size: 18px;
  color: #000000;
  border-width: 0;
  &:focus {
    outline: inherit;
  }
`;

const InputStyle = styled.input`
  ${InputCommonStyle}
`;

const InputConfirmStyle = styled.span`
  ${InputCommonStyle}
  margin-left: 20px;
`;

const InputAreaWrapper = styled.div`
  width: 100%;
  height: 300px;
  border-radius: 6px;
  border: solid 2px #707070;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
`;

const InputAreaConfirmWrapper = styled.div`
  display: flex;
  justify-content: center;
  overflow-wrap: break-word;
`;

const InputAreaCommonStyle = `
  height: 90%;
  width: 95%;
  margin-top: 20px;
  margin-bottom: 20px;
  line-height: 30px;
  font-size: 18px;
  color: #000000;
  border-width: 0;
  resize: none;
  &:focus {
    outline: inherit;
  }
`;

const InputAreaStyle = styled.textarea`
  ${InputAreaCommonStyle}
`;

const InputAreaConfirmStyle = styled.span`
  ${InputAreaCommonStyle}
`;

const langs = {
  thanks: {
    ja: 'お問い合わせありがとうございました。5秒後に自動でお問い合わせページへ戻ります。',
    en: 'Thank you for your sending message.',
  },
  name: { ja: '氏名', en: 'Name' },
  tel: { ja: '電話番号', en: 'Phone Number' },
  message: { ja: 'お問い合わせ内容', en: 'Message' },
  back: { ja: '戻る', en: 'Back' },
  send: { ja: '送信', en: 'Send' },
  failedSend: {
    ja: '申し訳ございません、送信できませんでした。',
    en: 'Sorry, failed to send your message.',
  },
  confirm: { ja: '確認', en: 'Confirm' },
  messagePlaceholder: {
    ja: '(お問い合わせ内容をご記入ください)',
    en: 'Please input your message here',
  },
};

const InputText = ({ label, value = '', required = false, confirm = false, onChange }) => (
  <Flex column>
    <Label>
      {label}
      {required ? <Required>*</Required> : ''}
    </Label>
    <Flex
      css={
        confirm
          ? ''
          : `
        border-bottom: solid 2px #3e3e3e;
        margin-bottom: 15px;
      `
      }
    >
      {!confirm ? (
        <InputStyle value={value} onChange={onChange} />
      ) : (
        <InputConfirmStyle>{value}</InputConfirmStyle>
      )}
    </Flex>
  </Flex>
);

const InputArea = ({
  label,
  value = '',
  required = false,
  confirm = false,
  onChange,
  lang = 'ja',
}) => (
  <Flex column>

    <Label>
      {label}
      {required ? <Required>*</Required> : ''}
    </Label>

    {!confirm ? (
      <InputAreaWrapper>
        <InputAreaStyle
          value={value}
          onChange={onChange}
          placeholder={langs.messagePlaceholder[lang]}
        />
      </InputAreaWrapper>
    ) : (
      <InputAreaConfirmWrapper>
        <InputAreaConfirmStyle>{value}</InputAreaConfirmStyle>
      </InputAreaConfirmWrapper>
    )}
  </Flex>
);

const Button = ({ text, disabled, onClick, color = '#f0862e' }) => (
  <button 
    css={`
      width: 166px;
      height: 40px;
      border-radius: 4px;
      background-color: ${disabled ? '#cccccc' : color};
      cursor: pointer;
    `}
    center
    onClick={onClick}
  >
    <span
      css={`
        font-size: 16px;
        color: #fcfcfc;
      `}
    >
      {text}
    </span>
  </button >
);

const pad = s => String(s).padStart(2, '0');

const uploadFile = async ({ Name, Kana, PatientID, Email, PhoneNumber, Message, From }) => {
  const d = new Date();
  const yyyy = d.getFullYear();
  const mm = pad(d.getMonth() + 1);
  const dd = pad(d.getDate());
  const formatedFullDate = `${yyyy}${mm}${dd}_${pad(d.getHours())}${pad(d.getMinutes())}${pad(
    d.getSeconds()
  )}${d.getMilliseconds()}`;
  const formatedMonthDate = `${mm}${dd}`;

  AWS.config.region = 'us-east-1';
  AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: 'us-east-1:82bfe5fd-764f-4079-99c2-4b97cffe400e',
  });
  AWS.config.credentials.get(err => {
    if (!err) {
      console.log('Cognito Identify Id: ' + AWS.config.credentials.identityId);
    }
  });
  const data = { Name, Kana, PatientID, Email, PhoneNumber, Message, From };
  const Region = 'us-east-1';
  const Bucket = 'hirahata-clinic.or.jp.contact';
  const ACL = 'authenticated-read';
  const s3 = new AWS.S3({ params: { Bucket, Region } });
  const Body = new Blob([JSON.stringify(data, null, 2)], { type: 'application/json' });
  const Key = `${yyyy}/${formatedMonthDate}/${formatedFullDate}.json`;
  try {
    const result = await s3
      .putObject({ Bucket, Key, ACL, Body, ContentType: 'application/json' })
      .promise();
    return { error: result.$response.error };
  } catch (e) {
    return { error: e };
  }
};

const Form = ({ lang = 'ja' }) => {
  const [name, setName] = useState('');
  const [furigana, setFurigana] = useState('');
  const [patientID, setPatientID] = useState('');
  const [email, setEmail] = useState('');
  const [tel, setTel] = useState('');
  const [message, setMessage] = useState('');
  const [confirm, setConfirm] = useState(false);
  const [showThanks, setShowThanks] = useState(false);
  const [notice, setNotice] = useState('');
  const [uploading, setUploading] = useState(false);
  const confirmDisabled = !name || !email || !message;
  const containerRef = useRef();
  const [scrollTop, setScrollTop] = useState(0);

  useEffect(() => {
    if (containerRef.current && !scrollTop) {
      const top = containerRef.current.getBoundingClientRect().top;
      setScrollTop((top >= 200 && top - 200) || top);
    }
  });

  // TODO ブラウザバック等でアラートを出したい場合は実装が必要

  if (showThanks) {
    return <Flex center>{langs.thanks[lang]}</Flex>;
  }

  return (
    <Flex column ref={containerRef}>
      {/* <p>
        <font size="4">直接メールをご希望の方は　<font size="6">　info@hirahata-clinic.or.jp　</font>　までお願いいたします。</font><br/>
      </p> */}

      <InputText
        label={langs.name[lang]}
        value={name}
        required={true}
        onChange={e => setName(e.target.value)}
        confirm={confirm}
      />
      {lang === 'ja' && (
        <InputText
          label="よみがな"
          value={furigana}
          onChange={e => setFurigana(e.target.value)}
          confirm={confirm}
        />
      )}
      <InputText
        label="当院の診察券番号（お分かりの方のみ）"
        value={patientID}
        onChange={e => setPatientID(e.target.value)}
        confirm={confirm}
      />
      <InputText
        label="E-mail"
        value={email}
        required={true}
        onChange={e => setEmail(e.target.value)}
        confirm={confirm}
      />
      <InputText
        label={langs.tel[lang]}
        value={tel}
        required={true}
        onChange={e => setTel(e.target.value)}
        confirm={confirm}
      />
      <InputArea
        label={langs.message[lang]}
        value={message}
        required={true}
        onChange={e => setMessage(e.target.value)}
        confirm={confirm}
        lang={lang}
      />
      {confirm ? (
        <Flex center>
          <Button
            text={langs.back[lang]}
            color="#9a9a9a"
            onClick={() => {
              window.scroll({ top: scrollTop, behavior: 'smooth' });
              setConfirm(false);
            }}
          />
          <Button
            text={langs.send[lang]}
            onClick={async () => {
              if (!uploading) {
                setUploading(true);
                window.scroll({ top: scrollTop, behavior: 'smooth' });
                const { error } = await uploadFile({
                  Name: name,
                  Kana: (lang === 'ja' && furigana) || (lang !== 'ja' && '-'),
                  PatientID: patientID,
                  Email: email,
                  PhoneNumber: tel,
                  Message: message,
                  From:"contact"
                });
                setUploading(false);
                if (error) {
                  console.error(error);
                  setNotice(langs.failedSend[lang]);
                } else {
                  setShowThanks(true);
                  setTimeout(() => {
                    setName('');
                    setFurigana('');
                    setPatientID('');
                    setEmail('');
                    setTel('');
                    setMessage('');
                    setConfirm(false);
                    setNotice('');
                    setShowThanks(false);
                  }, 5000);
                }
              }
            }}
          />
        </Flex>
      ) : (
        <Flex center>
          <Button
            text={langs.confirm[lang]}
            disabled={confirmDisabled}
            onClick={() => {
              if (!confirmDisabled) {
                setConfirm(true);
                window.scroll({ top: scrollTop, behavior: 'smooth' });
              }
            }}
          />
        </Flex>
      )}
      {notice ? (
        <Flex center>
          <Notice>{notice}</Notice>
        </Flex>
      ) : null}
    </Flex>
  );
};

export default Form;